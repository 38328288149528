@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300&display=swap");

/* @font-face {
  font-family: 'Muli-Light';
  src: local('Muli-Light');
  src: url('fonts/Muli-Light.ttf') format('truetype') !important;
  src: url('fonts/Muli-Light.woff') format('woff') !important;
  src: url('fonts/Muli-Light.woff2') format('woff2') !important;
  src: url('fonts/Muli-Light.otf') format('opentype') !important;
  src: url('fonts/Muli-Light.svg') format('svg') !important;
  font-style: normal;
  font-weight: normal;
} */

a {
  text-decoration: none !important;
}

.mainText {
  font-size: 1.2em;
}

body {
  font-family: "Muli", sans-serif !important;
  /* font-family: 'Muli-Light' !important; */
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  /* background-color: #004E98 !important; */
}

.header {
  background-image: url("images/header-img-main-1920px.png");
  background-size: cover;
  border-radius: 0px;
}

.bg-cover {
  margin-top: 10px;
  background-image: url("images/header-img-main-1080px.png");
  background-size: cover;
  background-position: center center;
  /* height: 400px;*/
  padding-top: 100%;
  width: auto;
  /* z-index: -2; */
}

.mainsection {
  font-size: 0.5em;
}

.core-features {
  background-image: url("images/core-features-img-1080px.png");
  background-size: cover;
  background-position: center center;
  /* height: 370px; */
  width: auto;
  padding-top: 80%;
  /* z-index: -2; */
}

.iconfullnode {
  background-image: url("images/mainchain-gradient.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-top: 15px;
  height: 100px;
  width: 50%;
}

.iconcarrier {
  background-image: url("images/icon-carrier-gradient.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-top: 15px;
  height: 100px;
  width: 50%;
}

.icondid {
  background-image: url("images/icon-DID-gradient.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-top: 15px;
  height: 100px;
  width: 50%;
}

.iconwallet {
  background-image: url("images/icon-wallet-gradient.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-top: 15px;
  height: 100px;
  width: 50%;
}

.iconrewards {
  background-image: url("images/rewards-blue-gradient-250.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-top: 15px;
  height: 100px;
  width: 50%;
}

.icondashboard {
  background-image: url("images/icon-dashboard-gradient.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-top: 15px;
  height: 100px;
  width: 50%;
}

.section1 {
  padding: 60px;
  font-size: 22px;
  min-height: 100%;
  align-items: center;
  background-color: #f2f2f2;
}

.section2 {
  padding: 60px;
  font-size: 22px;
  min-height: 100%;
  align-items: center;
  background-color: white;
}

.sectiontitle {
  font-size: 1.3em;
  font-weight: bold;
  color: #004e98;
}

.sectiontitle2 {
  font-size: 1.2em;
  font-weight: bold;
  color: #004e98;
}

.reward1 {
  width: 95%;
  height: auto;
}

.footer {
  width: 100%;
  height: 250px;
  background-color: #004e98;
  color: white;
  bottom: 0;
  position: absolute;
}

.footercontent {
  color: white;
}

.footerLeft {
  padding-left: 30px !important;
  font-size: 18px;
}

.footerRight {
  padding: 20px;
  padding-left: 30px !important;
  font-size: 18px;
}

@media only screen and (min-width: 450px) {
  .bg-cover {
    margin-top: 4%;
    background-image: url("images/header-img-main-1080px.png");
    background-size: cover;
    background-position: center center;
    /* height: 400px; */
    width: auto;
    padding-top: 100%;
    /* z-index: -2; */
  }

  .mainsection {
    font-size: 0.5em;
  }

  .reward1 {
    width: 95%;
    height: auto;
  }

  .core-features {
    background-image: url("images/core-features-img-1080px.png");
    background-size: cover;
    background-position: center center;
    /* height: 370px; */
    width: auto;
    padding-top: 80%;
    /* z-index: -2; */
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .bg-cover {
    background-image: url("images/header-img-main-1920px.png");
    background-size: cover;
    color: white;
    background-position: center center;
    width: 100%;
    padding-top: 50%;
    /* min-height: 800px; */
  }

  .mainsection {
    font-size: 0.9em;
  }

  .core-features {
    /* background-image: url('images/features.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%; */

    background-image: url("images/features-img-1920.png");
    background-size: cover;
    background-position: center;
    /* max-width: 1280px; */
    padding-top: 29%;
    height: 100%;

    /* background-position: 50% 50%; */
    /* height: 300px; */
    /* width: 1280px;
    height: 366px; */
  }

  .reward1 {
    width: 70%;
    height: auto;
  }

  .sectiontitle {
    font-size: 2em;
    font-weight: bold;
    color: #004e98;
  }

  .footer {
    height: 255px;
    background-color: #004e98;
    color: white;
    position: absolute;
    bottom: -10px !important;
    width: 100%;
    margin-left: 0px !important;
  }

  .footercontent {
    color: white;
  }

  .footerLeft {
    padding: 60px 110px !important;
    font-size: 18px;
  }

  .footerRight {
    padding: 60px 60px !important;
    font-size: 18px;
  }

  .mainText {
    font-size: 1.2em;
  }

  .smallText {
    font-size: 0.8em;
  }
}
